<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="hcard relative z-10 p-7" v-if="loading">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-5 mt-2 animate-pulse">
          <div class="relative" v-for="b in 4" :key="b">
            <div class="hcard-b17 flex flex-col p-5 h-full">
              <div class="flex-grow">
                <div class="grid-cols-1 mb-7">
                  <div class="h-6 w-32 bg-hline rounded-lg"></div>

                  <div class="h-0.5 w-full my-3 bg-hline rounded-lg"></div>

                  <div class="h-5 w-68 bg-hline rounded-lg"></div>
                  <div class="h-5 w-56 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-60 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-28 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-38 mt-1 bg-hline rounded-lg"></div>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="">
                  <div class="h-7 w-24 bg-hline rounded-lg"></div>
                </div>
                <div class="relative">
                  <div class="h-5 w-28 bg-hline rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hcard relative z-10 p-7" v-else>
        <div class="z-10">
          <div class="flex items-center rounded-lg py-2" style="width: 100%">
            <IconArrow
              class="cursor-pointer mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
              @click="$router.go(-1)"
              style="margin-top: auto; margin-bottom: auto"
            />
            <span class="text-2xl text-normal">Training Certificate List</span>
            <div class="flex items-center ml-auto relative" style="width: 40%">
              <input
                v-model="searchQuery"
                type="text"
                placeholder="Search certificate, name, or title...."
                class="input input-bordered w-full"
                style="border-radius: 28px"
              />
              <IconSearch
                class="absolute right-3 transform -translate-y-1/2 text-gray-500"
                :style="{ top: '45%' }"
              />
            </div>
          </div>
        </div>
        <div class="grid mb-8 px-3" v-if="$route.query.instrument">
          <div class="flex mt-1 mb-1">
            <div class="mr-4 pt-0.5">
              <SvgInstrumentXs class="w-5 h-5" />
            </div>
            <p class="text-lg font-semibold font-Roboto text-htext">Instrument&nbsp;:</p>
          </div>
          <div class="text-32px font-semibold" v-if="certificates && certificates.length">
            {{ certificates[0].certificate.instrument_type }}
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-2">
          <div class="col-span-2 py-24" v-if="!certificates.length">
            <img :src="NoData" class="mx-auto" />
            <div class="capitalize font-semibold text-5xl text-hblue text-center">
              there is no certificate yet!
            </div>
          </div>

          <div
            class="hcard-cert mx-auto hover:bg-hgray6 cursor-pointer"
            style="max-width: 100%"
            v-for="b in certificates"
            :key="b.id"
            @click="openMenu(b)"
          >
            <img :src="BgCert" class="absolute right-0 top-0 rounded-tr-28px" />

            <div class="flex flex-col py-5 h-full">
              <div class="flex-grow">
                <div class="grid-cols-1 mb-7">
                  <div class="font-semibold text-htext text-xl pl-7 z-10">Training</div>
                  <div
                    class="bg-hwarning text-white font-Roboto text-sm py-1 pl-7 pr-2 rounded-r-full max-w-65p mt-1.5"
                  >
                    {{ b.certificate_number }}
                  </div>
                  <div class="font-Roboto text-xs pl-7 mt-1">
                    <span class="text-black">Which was held on </span>
                    <span class="text-htext font-semibold">
                      {{ formattedIssuedDate(b.certificate.issued_date) }}
                    </span>
                  </div>

                  <div class="flex justify-between content-end px-7 mb-2 mt-3">
                    <div class="relative">
                      <div class="text-xs text-black font-semibold">Name :</div>
                      <div class="text-xl text-htext font-semibold">
                        {{ b.participant_name }}
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-between content-end px-7 mb-2">
                    <div class="relative">
                      <div class="text-xs font-Roboto font-medium">Instrument :</div>
                      <div class="text-sm text-black font-semibold">
                        {{ b.certificate.instrument_type }}
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-between content-end px-7 mb-2">
                    <div class="relative">
                      <div class="text-xs font-Roboto font-medium">Training Title :</div>
                      <div class="text-sm text-black font-semibold">
                        {{ b.certificate.training_title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-between content-end px-7">
                <div class="relative">
                  <div class="text-xs font-Roboto font-medium">Trainer:</div>
                  <div class="text-sm text-htext font-semibold">
                    <span v-if="b.certificate.trainer_name">{{ b.certificate.trainer_name }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="relative" v-if="b.certificate.expired_date">
                  <div class="text-xs font-Roboto font-medium">Expired Date:</div>
                  <div class="text-sm text-htext font-semibold">
                    <span>
                      {{ formattedIssuedDate(b.certificate.expired_date) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex justify-between space-x-2 mx-auto mt-8 text-center"
          style="max-width: 100px"
        >
          <button
            class="btn font-semibold bg-hteal1 hover:bg-hteal1 rounded-full btn-sm border-0"
            :disabled="currentPage == 1"
            @click="pageDecrease"
          >
            «
          </button>
          <button
            class="btn font-semibold rounded-full btn-sm btn-ghost no-animation hover:border-transparent"
          >
            {{ currentPage }}
          </button>
          <button
            class="btn font-semibold bg-hteal1 hover:bg-hteal1 rounded-full btn-sm border-0"
            :disabled="currentPage == totalPage"
            @click="pageIncrease"
          >
            »
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconSearch from '@/assets/icons/icon-search.svg?inline'
import SvgInstrumentXs from '@/assets/icons/icon-instrument.svg?inline'

import NoData from '@/assets/images/icon-noCertificate.png'
import BgCert from '@/assets/images/bg-cert.png'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'certifcates',
  components: {
    IconArrow,
    IconSearch,
    SvgInstrumentXs
  },
  data() {
    return {
      NoData,
      BgCert,
      loading: true,
      currentPage: 1,
      totalPage: 1,
      searchQuery: '',
      isInitialLoad: true,
      debounceTimeout: null
    }
  },
  watch: {
    searchQuery() {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.getData()
      }, 500)
    },
    '$route.query': {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  computed: {
    ...mapState({
      certificates: state => state.instrument.certificatesTraining,
      user: state => state.auth.user
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('instrument', ['GET_CERTIFICATES_TRAINING']),
    ...mapActions('modal', ['MODAL_CERTIFICATE']),
    async getData() {
      if (this.isInitialLoad) {
        this.loading = true
      }

      let queries = JSON.parse(JSON.stringify(this.$route.query))
      queries.filter = this.searchQuery

      const res = await this.GET_CERTIFICATES_TRAINING(queries)

      if (res && res.pagination) {
        this.totalPage = res.pagination.total_pages
      }
      setTimeout(() => {
        if (this.isInitialLoad) {
          this.loading = false
          this.isInitialLoad = false
        }
      }, 500)
    },
    pageDecrease() {
      if (this.currentPage > 1) {
        this.currentPage -= 1
        this.changePage(this.currentPage)
      }
    },
    pageIncrease() {
      if (this.currentPage < this.totalPage) {
        this.currentPage += 1
        this.changePage(this.currentPage)
      }
    },
    changePage(val) {
      let queries = JSON.parse(JSON.stringify(this.$route.query))
      queries.page = val

      this.$router.replace({ query: queries })
    },
    openMenu(val) {
      this.MODAL_CERTIFICATE(val)
    },
    formattedIssuedDate(issued_date) {
      const issuedDate = new Date(issued_date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      })
      return issuedDate
    }
  }
}
</script>
